import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@wam/icons';

import { NavItemComponent } from './nav-item.component';

@NgModule({
  declarations: [NavItemComponent],
  imports: [CommonModule, IconModule],
  exports: [NavItemComponent],
})
export class NavItemModule {}
